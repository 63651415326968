import axios from "axios";

// Create an axios instance
const api = axios.create({
  baseURL: "https://break.fischerjordan.xyz",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//function to perform registration
const userRegistration = async (email, password, name, phone, dynamicUrl) => {
  try {
    const response = await api.post(`/api/community/${dynamicUrl}/`, {
      email,
      password,
      name,
      phone,
    });

    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      return { data, status: "success" };
    } else {
      const data = response.data;
      return { data, status: "error" };
    }
  } catch (error) {
    return {
      data: error.response ? error.response.data : error.message,
      status: "error",
    };
  }
};
//function to perform login
const login = async (username, password) => {
  try {
    const response = await api.post("/api/auth_token/", username, password);
    if (response.status >= 200 && response.status < 300) {
      const token = response.data.token;
      const logStats = { token, status: "success", data: response.data };
      return logStats;
    } else {
      const logStats = {
        token: "",
        status: "error",
        data: response.data,
      };
      return logStats;
    }
  } catch (error) {
    const logStats = { token: "", status: "error", data: error.response.data };
    return logStats;
  }
};

//function to perform password reset
const resetPassword = async (email, old_password, new_password) => {
  try {
    const response = await api.post(
      "/api/change_password/",
      email,
      old_password,
      new_password
    );
    if (response.status >= 200 && response.status < 300) {
      const token = response.data.token;
      const logStats = { token, status: "success", data: response.data };
      return logStats;
    } else {
      const logStats = {
        token: "",
        status: "error",
        data: response.data,
      };
      return logStats;
    }
  } catch (error) {
    const logStats = { token: "", status: "error", data: error.response.data };
    return logStats;
  }
};
//function to perform password reset
const forgotPassword = async (email) => {
  try {
    const response = await api.post("/api/forgot_password/", email);
    if (response.status >= 200 && response.status < 300) {
      const token = response.data.token;
      const logStats = { token, status: "success", data: response.data };
      return logStats;
    } else {
      const logStats = {
        token: "",
        status: "error",
        data: response.data,
      };
      return logStats;
    }
  } catch (error) {
    const logStats = { token: "", status: "error", data: error.response.data };
    return logStats;
  }
};

// Function to get section_break_results
const getSectionBreakRes = async (email) => {
  try {
    const response = await api.get(`/api/section_break/${email}`);
    const sectionBreakResults = {
      data: response.data,
      status: "success",
    };
    return sectionBreakResults;
  } catch (error) {
    if (error.response) {
      throw new Error(`Error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error(
        "No response from server. Please check your internet connection."
      );
    } else {
      // Something else caused the error
      throw new Error(error.message);
    }
  }
};

//function to delete User Response Request
const deleteUserResponseRequest = async (email) => {
  try {
    const response = await api.delete(`/api/delete_user_response/${email}`);

    return { status: "success" };
  } catch (error) {
    return { status: "error" };
  }
};

//function to delete User Timeline Response Request
const deleteUserTimelineResponseRequest = async (email) => {
  try {
    const response = await api.delete(
      `/api/delete_user_timeline_response/${email}`
    );

    return { status: "success" };
  } catch (error) {
    return { status: "error" };
  }
};

//function to save current question
const saveCurrentQueston = async (
  diagnostic_response_id,
  current_question_id,
  response,
  section
) => {
  try {
    const result = await api.post(
      "/api/save_current_question/",
      diagnostic_response_id,
      current_question_id,
      response,
      section
    );

    return { data: result.data, status: "success" };
  } catch (error) {
    if (error.response) {
      throw new Error(`Error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error(
        "No response from server. Please check your internet connection."
      );
    } else {
      // Something else caused the error
      throw new Error(error.message);
    }
  }
};

//function to get model responses
const getModelResponse = async (email) => {
  try {
    const result = await api.get(`/api/model_response/${email}`);

    return { data: result.data, status: "success" };
  } catch (error) {
    if (error.response) {
      throw new Error(`Error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error(
        "No response from server. Please check your internet connection."
      );
    } else {
      // Something else caused the error
      throw new Error(error.message);
    }
  }
};

// Function to check token
const checkToken = async (username, password) => {
  try {
    const response = await api.post("/api/break_portal_auth/", {
      username,
      password,
    });
    return { data: response.data, status: "success" };
  } catch (error) {
    return { data: error.response.data, status: "error" };
  }
};

// Function to check token
const checkPaymentToken = async (campaign, checkout_session_id) => {
  try {
    const response = await api.post("/api/verify_checkout/", {
      campaign,
      checkout_session_id,
    });
    return { data: response.data, status: "success" };
  } catch (error) {
    return { data: error.response.data, status: "error" };
  }
};

// Function to post query
const postQuery = async (email, name, query) => {
  try {
    const response = await api.post("/api/helpdesk_query/", {
      email,
      name,
      query,
    });
    return { data: response.data, status: "success" };
  } catch (error) {
    return { data: error.response.data, status: "error" };
  }
};

// Function to get user status of the start page
const getStartPageStatus = async (email) => {
  try {
    const response = await api.get(`/api/get_user_start_status/${email}`);
    return { data: response.data, status: "success" };
  } catch (error) {
    return { data: error.response.data, status: "error" };
  }
};

// Function to get user status of the start page
const sendStartPageStatus = async (email, status) => {
  try {
    const response = await api.post("/api/update_user_status/", {
      email,
      status,
    });
    return { data: response.data, status: "success" };
  } catch (error) {
    return { data: error.response.data, status: "error" };
  }
};
//function to verify user registration with otp
const verifyOtp = async (email, otp) => {
  try {
    const response = await api.post(`/api/verify_user_otp/${email}/`, { otp });
    return response.data;
  } catch (error) {
    const status = { data: error.response.data, status: "error" };
    return status;
  }
};
//function to verify user registration with otp
const reportPageStaticDetails = async () => {
  try {
    const response = await api.get(`/api/report_page/`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error(
        "No response from server. Please check your internet connection."
      );
    } else {
      // Something else caused the error
      throw new Error(error.message);
    }
  }
};

export {
  userRegistration,
  login,
  getSectionBreakRes,
  deleteUserResponseRequest,
  deleteUserTimelineResponseRequest,
  saveCurrentQueston,
  getModelResponse,
  checkToken,
  checkPaymentToken,
  postQuery,
  getStartPageStatus,
  sendStartPageStatus,
  resetPassword,
  forgotPassword,
  verifyOtp,
  reportPageStaticDetails,
};
