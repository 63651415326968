"use client";
//chakra ui imports
import {
  Button,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  Center,
  Input,
  Box,
  Stack,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Image,
  useToast,
  Flex,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";

//react imports
import { useState, useContext } from "react";

//react-icons imports
import { BsArrowRightShort } from "react-icons/bs";

//auth import
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { login, userRegistration } from "../Utils/api";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../Utils/auth";
const Signup = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customLink } = useParams();
  const dynamicUrl = customLink || "break_admin-admin-1";
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const getLogin = async () => {
    setLoading(true);
    try {
      const res = await login({
        username: email,
        password,
      });
      if (res.error) {
        toast({
          title: "Login Failed",
          description: res.error,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        authCtx.login(res.data.token, res.data.email, res.data.name);
        window.location = "/";
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Login Failed",
        description: "An unexpected error occurred during login.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const createAccount = async () => {
    setLoading(true);
    try {
      const signupRes = await userRegistration(
        email,
        password,
        name,
        phone,
        dynamicUrl
      );
      if (signupRes.status === "success") {
        toast({
          title: "Account Created successfully.",
          description: signupRes.message,
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        getLogin();
      } else {
        handleSignupError(signupRes);
      }
    } catch (error) {
      console.log(error);
      handleSignupError(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSignupError = (error) => {
    console.error("Error during signup:", error);
    toast({
      title: "Signup Failed.",
      description: "An unexpected error occurred during signup.",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  };


  const handleChange = (event, setStateFunction) => {
    const { type, checked, value } = event.target;
    setStateFunction(type === "checkbox" ? checked : value);
  };


  return (
    <Stack h="100vh" direction={{ base: "column", md: "row" }}>
      <Flex flex={1} alignItems="center" justify="center">
        <Stack
          p={5}
          w={"95vw"}
          maxW={"xl"}
          style={{
            borderWidth: "1px",
            borderRadius: "10px",
            marginTop: "20px",
          }}
          border="1px solid black"
        >
          <Center>
            <Image
              src="/break_logo.avif"
              width="auto"
              height="6vh"
              objectFit="contain"
            />
          </Center>
          <Box>
            <Text fontSize={"24px"} fontWeight={700}>
              Create Account
            </Text>
            <Text fontSize={"14px"} color={"#717171"}>
              Let's get started with your Brain Pattern Mapping.
            </Text>
          </Box>

          <Stack spacing={4}>
            <FormControl isRequired id="name">
              <FormLabel fontSize={"16px"}>Full Name</FormLabel>
              <Input
                size="md"
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(event) => handleChange(event, setName)}
              />
            </FormControl>

            <FormControl isRequired id="email">
              <FormLabel fontSize={"16px"}>Email Address</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  size="md"
                  type="email"
                  placeholder="Enter your Email Address"
                  value={email}
                  onChange={(event) => handleChange(event, setEmail)}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired id="phone">
              <FormLabel fontSize={"16px"}>Phone Number</FormLabel>
              <InputGroup size="md">
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="tel"
                  placeholder="Enter your Phone Number"
                  value={phone}
                  onChange={(event) => handleChange(event, setPhone)}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired id="password">
              <FormLabel fontSize={"16px"}>Enter Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="At least 8 characters long"
                  value={password}
                  onChange={(event) => handleChange(event, setPassword)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Checkbox
              color={"rgba(0, 0, 0, 0.50)"}
              m={"6px 0 6px 0"}
              letterSpacing={"-0.14px"}
              fontSize={"14px"}
              onChange={(event) => handleChange(event, setIsChecked)}
            >
              I agree to all
              <Text as={"span"} color={"black"}>
                {" "}
                Terms{" "}
              </Text>
              and the
              <Text as={"span"} color={"black"}>
                {" "}
                Privacy Policy
              </Text>
              .
            </Checkbox>

            <Button
              size="md"
              bg="black"
              color="white"
              onClick={createAccount}
              rightIcon={<BsArrowRightShort />}
              isLoading={loading}
              isDisabled={!email || !password || !name || !isChecked}
              _hover={{
                bg: "gray",
              }}
              _disabled={{
                bg: "black",
                cursor: "not-allowed",
              }}
            >
              Create my account
            </Button>
          </Stack>

          <Stack spacing={4}>
            <Text>
              Already have an account?{" "}
              <Text
                onClick={() => {
                  navigate("/login");
                }}
                cursor="pointer"
                as={"span"}
                color={"black"}
                fontWeight="600"
              >
                Login here.
              </Text>
            </Text>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default Signup;
