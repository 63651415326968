import React, { useEffect } from "react";

const CalendlyWidget = () => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);

  return (
    <div>
      <div
        className="calendly-inline-widget"
        data-url={"https://calendly.com/gordon_breakmethod/brain-pattern-mapping"}
        style={{ minWidth: "320px", height: "700px" }}
      />
    </div>
  );
};

export default CalendlyWidget;
