import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Heading,
  Text,
  Flex,
  Image,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import AuthContext from "../Utils/auth";
import ReactPlayer from "react-player";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "../index.css";
import CalendlyWidget from "../components/CalendlyWidget";
const LandingNew = () => {
  const authCtx = useContext(AuthContext);
  const user = authCtx.name
    ? capitalizeFirstLetter(authCtx.name)
    : authCtx.name;
  const location = useLocation();
  const { parameter, staticData } = location.state || {};
  function capitalizeFirstLetter(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    });
    return capitalizedWords.join(" ");
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const videoWidth = isMobile ? "300px" : "550px";
  const videoHeight = isMobile ? "170px" : "310px";
  const video0Ref = useRef(null);
  const video1Ref = useRef(null);

  const handleRefButtonClick = (targetRef) => {
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const isPaidUser = [
    "social",
    "retargeting",
    "men",
    "women",
    "general",
  ].includes(staticData?.toLowerCase());
  return (
    <>
      <div ref={video0Ref} />
      <Flex
        minHeight="100vh"
        justifyContent="center"
        flexDir="column"
        bgImg="https://i.imgur.com/NElmTZV.png"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        alignItems="center"
      >
        <Flex
          flexDir="column"
          alignItems="center"
          width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
          justifyContent="center"
          flex="1"
        >
          {(() => {
            switch (parameter) {
              case "report":
                return (
                  <>
                    <Image
                      src="break_logo.png"
                      alt="Logo"
                      width={["150px", "150px", "200px", "200px", "200px"]}
                      mb="20px"
                      mt="60px"
                    />
                    <Heading
                      fontFamily="Avenir"
                      color="white"
                      fontWeight="900"
                      width="85%"
                      textAlign="center"
                      fontSize={["24px", "24px", "24px", "32px", "32px"]}
                    >
                      YOUR BRAIN PATTERN MAP IS READY.
                    </Heading>
                    {isPaidUser ? (
                      <Text
                        color="white"
                        textAlign="center"
                        mt="20px"
                        fontSize={["18px", "18px", "18px", "20px", "20px"]}
                        width={["85%", "85%", "85%", "700px", "700px"]}
                        mb="0px"
                      >
                        Use the calendar link below to book your session to
                        review your Brain Pattern Map. On this session, a
                        Behavior Strategist will deliver your Brain Pattern Map
                        and teach you how to understand it.
                      </Text>
                    ) : (
                      <Text
                        color="white"
                        textAlign="center"
                        mt="20px"
                        fontSize={["18px", "18px", "18px", "20px", "20px"]}
                        width={["85%", "85%", "85%", "700px", "700px"]}
                        mb="0px"
                      >
                        Thank you for submitting your responses to the Brain
                        Pattern Mapping questionnaire. Your behavior strategist
                        will review your results and your Brain Pattern Map with
                        you in your next session.
                      </Text>
                    )}

                    {isPaidUser && (
                      <Flex
                        width={{ base: "100%", md: "1200px" }}
                        justifyContent="center"
                        flexDir="column"
                        m="auto"
                        height="auto"
                        mb={["40px", "40px", "40px", "auto", "auto"]}
                      >
                        <CalendlyWidget />
                      </Flex>
                    )}
                  </>
                );
              case "start":
                return (
                  <>
                    <Image
                      src="break_logo.png"
                      alt="Logo"
                      width={["150px", "150px", "200px", "200px", "200px"]}
                      mb="20px"
                    />
                    <Heading
                      fontFamily="Avenir"
                      color="white"
                      fontWeight="900"
                      width="85%"
                      textAlign="center"
                      fontSize={["24px", "24px", "24px", "32px", "32px"]}
                    >
                      Welcome, {user}.
                    </Heading>
                    <Text
                      color="white"
                      textAlign="center"
                      mt="20px"
                      fontSize={["18px", "18px", "18px", "20px", "20px"]}
                      width={["85%", "85%", "85%", "650px", "650px"]}
                    >
                      <strong>
                        Get ready to understand why you see the world the way
                        you do, what mechanisms created your blind spots and
                        actionable steps you can take to rapidly rewire.
                      </strong>{" "}
                      <br></br>
                      <br></br>
                      Click the button below to get started with your
                      diagnostic. We’ve also given you access to bonus videos
                      that you can view at your convenience.
                      <br></br>
                      <br></br>
                      You can start now. If you need to stop at any point and
                      come back, we’ll save your progress.
                    </Text>
                    <Flex mt="40px" mb="40px" gap="12px">
                      <a
                        href="/diagnostic"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          bg="yellow"
                          width="260px"
                          fontWeight="900"
                          fontSize={["12px", "12px", "12px", "16px", "16px"]}
                          m="auto"
                          as="a"
                          href="/diagnostic"
                          target="_blank"
                          _hover={{
                            bg: "#b2b200",
                          }}
                        >
                          START MY DIAGNOSTIC
                        </Button>
                      </a>
                    </Flex>
                  </>
                );
              case "progress":
                return (
                  <>
                    <Image
                      src="break_logo.png"
                      alt="Logo"
                      width={["150px", "150px", "200px", "200px", "200px"]}
                      mb="20px"
                    />
                    <Heading
                      fontFamily="Avenir"
                      color="white"
                      fontWeight="900"
                      width="85%"
                      textAlign="center"
                      fontSize={["24px", "24px", "24px", "32px", "32px"]}
                    >
                      Welcome back, {user}.
                    </Heading>
                    <Box>
                      <Text
                        color="white"
                        textAlign="center"
                        mt="20px"
                        fontSize={["18px", "18px", "18px", "20px", "20px"]}
                        width={["85%", "85%", "85%", "650px", "650px"]}
                      >
                        <strong>
                          Get ready to understand why you see the world the way
                          you do, what mechanisms created your blind spots and
                          actionable steps you can take to rapidly rewire.
                        </strong>{" "}
                        <br></br>
                        <br></br>
                        Click the button below to resume your diagnostic. We’ve
                        also given you access to bonus videos that you can view
                        at your convenience.
                        <br></br>
                        <br></br>
                      </Text>
                      <Text
                        color="yellow"
                        textAlign="center"
                        mt="0px"
                        fontSize={["18px", "18px", "18px", "20px", "20px"]}
                        width={["85%", "85%", "85%", "650px", "650px"]}
                      >
                        You’re almost there. We just need you to complete a few
                        more questions to finish the diagnostic.
                      </Text>
                    </Box>
                    <Flex mt="40px" mb="40px" gap="12px">
                      <a
                        href="/diagnostic"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          bg="yellow"
                          width="260px"
                          fontWeight="900"
                          fontSize={["12px", "12px", "12px", "16px", "16px"]}
                          m="auto"
                          as="a"
                          _hover={{
                            bg: "#b2b200",
                          }}
                        >
                          COMPLETE MY DIAGNOSTIC
                        </Button>
                      </a>
                    </Flex>
                  </>
                );
            }
          })()}
        </Flex>
        <Flex
          alignItems="center"
          flexDir="column"
          width="100%"
          textAlign="center"
          justifyContent="center"
          cursor="pointer"
          py="2"
          onClick={() => handleRefButtonClick(video1Ref)}
        >
          <Text
            color="white"
            fontSize={["12px", "12px", "12px", "14px", "14px"]}
          >
            BONUS VIDEOS BELOW
          </Text>
          <ChevronDownIcon w={6} h={6} color="white" />
        </Flex>
      </Flex>
      {/* ==== */}
      {/* VIDEO LECTURE 1 SECTION */}
      {/* ==== */}
      <div ref={video1Ref} />
      <Flex
        flexDir="column"
        justifyContent="center"
        width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        maxWidth={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        m="auto"
        p="40px 0"
      >
        <Button
          bg="yellow"
          width="fit-content"
          fontWeight="900"
          fontSize={["12px", "12px", "12px", "16px", "16px"]}
          m="auto"
          as="a"
          href="https://www.dropbox.com/scl/fi/soz6w8s1tzjb2wb5avb0u/BPM-Workbook-Lecture-Slides.pdf?rlkey=m3iiqnfjb63e7f0i5wj4xppb1&st=rqi85uld&dl=0"
          target="_blank"
          _hover={{
            bg: "black",
            color: "white",
          }}
          whiteSpace="normal"
          textAlign="center"
        >
          STEP 1: DOWNLOAD YOUR BONUS WORKBOOK COMPANION
        </Button>
        {/* VIDEO TITLE */}
        <Heading
          fontFamily="Avenir"
          textAlign="left"
          fontWeight="900"
          fontSize={["22px", "22px", "22px", "28px", "28px"]}
          width="100%"
          mt="40px"
          lineHeight="1.5"
        >
          Step 2: Watch the video lecture on Timeline Processing
        </Heading>

        {/* ROW ITEM */}
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          flexDir={["column", "column", "column", "row", "row"]}
          m="40px 0px 80px 0px"
        >
          {/* TEXT ITEM */}
          <Flex flexDir="column" width={["100%", "100%", "100%", "50%", "50%"]}>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
            >
              Objective
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              The lens through which you view your world is a{" "}
              <strong>byproduct of your childhood experiences.</strong> This
              lecture discusses the specific factors that shape your brain’s
              definition of safety and walks you through a series of prompts to
              help you{" "}
              <strong>
                tap into the information needed to complete your Brain Pattern
                Mapping.
              </strong>
            </Text>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
              mt="40px"
            >
              Takeaway
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              Your brain has been patterned by the{" "}
              <strong>
                cause and effect relationship of your adversely perceived
                repetitive childhood experiences.
              </strong>{" "}
              This pattern impacts how you engage with the world around you.
            </Text>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
              mt="40px"
            >
              Action Step
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              Start to consider <strong>what was happening around you</strong>{" "}
              and who the most influential <strong>cast of character</strong>{" "}
              were in your early years.{" "}
              <strong>Respond to the prompts on pages 22-26</strong> and have
              your notes ready for the next lecture.
            </Text>
          </Flex>

          {/* VIDEO ITEM */}
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDir="column"
            width={["100%", "100%", "100%", "50%", "50%"]}
            mt="40px"
          >
            <Flex
              width="fit-content"
              alignSelf={["center", "center", "center", "flex-end", "flex-end"]}
              flexDir="column"
              alignItems="center"
            >
              <Flex
                width={videoWidth}
                height={videoHeight}
                background="black"
                alignSelf="flex-start"
              >
                <ReactPlayer
                  url="https://player.vimeo.com/video/863676616?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  controls={true}
                  playbackRate={1.0}
                  config={{
                    vimeo: {
                      playerOptions: {
                        autopause: 0,
                        transparent: true,
                        loop: false,
                        muted: false,
                        portrait: false,
                        fullscreen: false,
                        seek: false,
                        responsive: true,
                        controls: true,
                        keyboard: true,
                        progress_bar: true,
                      },
                    },
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* ==== */}
      {/* END VIDEO LECTURE SECTION */}
      {/* ==== */}

      {/* ==== */}
      {/* VIDEO LECTURE 2 SECTION */}
      {/* ==== */}

      <Flex width="100%" bg="black" height="fit-content" position="relative">
        <Flex
          flexDir="column"
          justifyContent="center"
          width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
          m="auto"
          p="40px 0"
        >
          {/* VIDEO TITLE */}
          <Heading
            fontFamily="Avenir"
            textAlign="left"
            fontWeight="900"
            fontSize={["22px", "22px", "22px", "28px", "28px"]}
            width="100%"
            mt="40px"
            color="white"
            lineHeight="1.5"
          >
            Step 3: Watch the video lecture on the Timeline Process Exercise
          </Heading>

          {/* ROW ITEM */}
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            flexDir={[
              "column-reverse",
              "column-reverse",
              "column-reverse",
              "row",
              "row",
            ]}
            m="40px 0px 80px 0px"
            alignItems="center"
          >
            {/* VIDEO ITEM */}
            <Flex
              justifyContent="center"
              alignItems="center"
              mt="40px"
              flexDir="column"
              width={["100%", "100%", "100%", "50%", "50%"]}
            >
              <Flex
                width="fit-content"
                alignSelf={[
                  "center",
                  "center",
                  "center",
                  "flex-start",
                  "flex-start",
                ]}
                flexDir="column"
                alignItems="center"
              >
                <Flex
                  style={{
                    width: videoWidth,
                    height: videoHeight,
                    background: "black",
                    alignSelf: "flex-start",
                  }}
                >
                  <ReactPlayer
                    url="https://player.vimeo.com/video/853157907?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    controls={true}
                    playbackRate={1.0}
                    config={{
                      vimeo: {
                        playerOptions: {
                          autopause: 0,
                          transparent: true,
                          loop: false,
                          muted: false,
                          portrait: false,
                          fullscreen: false,
                          seek: false,
                          responsive: true,
                          controls: true,
                          keyboard: true,
                          progress_bar: true,
                        },
                      },
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>

            {/* TEXT ITEM */}
            <Flex
              flexDir="column"
              width={["100%", "100%", "100%", "50%", "50%"]}
            >
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                color="white"
              >
                Objective
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
                color="white"
              >
                The Timeline Process Exercise is an{" "}
                <strong>
                  important tool that serves as a foundation for your Brain
                  Pattern Map.
                </strong>{" "}
                This lecture provides a step-by-step breakdown of how to
                <strong>identify and summarize</strong> your repetitive
                childhood experiences into{" "}
                <strong>
                  3 concise data streams, each linked to an emotion.
                </strong>
              </Text>
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                mt="40px"
                color="white"
              >
                Takeaway
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
                color="white"
              >
                How you perceived safety and stability as a child provides us
                with more insight than the objective truth here.
                <strong>
                  Anything that does not support innocence, curiosity or love,
                  when experienced with repetition, can be a source of pain and
                  suffering.
                </strong>{" "}
                How you responded across the timeline between ages 0-14 informs
                your origin, protective and escalating emotions.
              </Text>
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                mt="40px"
                color="white"
              >
                Action Step
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
                color="white"
              >
                <strong>Complete pages 33 and 35</strong> of your Brain Pattern
                Mapping Workbook. Do your best to answer the prompts in a
                summarized way, focusing on details such as your{" "}
                <strong>
                  behavior, voice tone, body language, decision making, and the
                  general tendencies{" "}
                </strong>
                in your daily life. Refer back to the working definitions of
                emotional states when describing your emotional response. Have
                these pages ready for your diagnostic session.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* ==== */}
      {/* END VIDEO LECTURE SECTION */}
      {/* ==== */}

      {/* ==== */}
      {/* VIDEO LECTURE 3 SECTION */}
      {/* ==== */}
      <Flex width="100%" height="fit-content" position="relative">
        <Flex
          flexDir="column"
          justifyContent="center"
          width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
          maxWidth={["90vw", "90vw", "90vw", "1200px", "1200px"]}
          m="auto"
          pt="40px"
        >
          {/* VIDEO TITLE */}
          <Heading
            fontFamily="Avenir"
            textAlign="left"
            fontWeight="900"
            fontSize={["22px", "22px", "22px", "28px", "28px"]}
            width="100%"
            mt="40px"
          >
            Step 4: Watch the video lecture on Source Belief Pattern Etiologies
          </Heading>

          {/* ROW ITEM */}
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            flexDir={["column", "column", "column", "row", "row"]}
            alignItems="center"
            m="40px 0px 40px 0px"
          >
            {/* TEXT ITEM */}
            <Flex
              flexDir="column"
              width={["100%", "100%", "100%", "50%", "50%"]}
            >
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
              >
                Objective
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
              >
                This lecture{" "}
                <strong>
                  walks you through the initial stages of understanding how your
                  childhood shaped your beliefs
                </strong>{" "}
                about people, situations and the world around you. You'll learn
                about how origin and adaptive source belief patterns are formed
                and how they shape the way you perceive reality, respond to your
                environment and make decisions about your future.
              </Text>
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                mt="40px"
              >
                Takeaway
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
              >
                In preparation for your upcoming Brain Pattern Mapping session,
                you should have a basic understanding of the different source
                beliefs and what <strong>inputs</strong> contributed to the{" "}
                <strong>output</strong> of who you are. Understanding{" "}
                <strong>where your brain is fundamentally stuck</strong> in
                terms of socio-emotional maturity is an important step in the
                diagnostic process.
              </Text>
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                mt="40px"
              >
                Action Step
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
              >
                Block off <strong>30 minutes of uninterrupted time</strong> on
                your calendar to complete your online diagnostics session using
                the computer model.
              </Text>
            </Flex>

            {/* VIDEO ITEM */}
            <Flex
              justifyContent="center"
              alignItems="center"
              mt="40px"
              flexDir="column"
              width={["100%", "100%", "100%", "50%", "50%"]}
            >
              <Flex
                width="fit-content"
                alignSelf={[
                  "center",
                  "center",
                  "center",
                  "flex-end",
                  "flex-end",
                ]}
                flexDir="column"
                alignItems="center"
              >
                <Flex
                  width={videoWidth}
                  height={videoHeight}
                  background="black"
                  alignSelf="flex-end"
                >
                  <ReactPlayer
                    url="https://player.vimeo.com/video/853169120?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    controls={true}
                    playbackRate={1.0}
                    config={{
                      vimeo: {
                        playerOptions: {
                          autopause: 0,
                          transparent: true,
                          loop: false,
                          muted: false,
                          portrait: false,
                          fullscreen: false,
                          seek: false,
                          responsive: true,
                          controls: true,
                          progress_bar: true,
                        },
                      },
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* ==== */}
      {/* END VIDEO LECTURE SECTION */}
      {/* ==== */}

      {/* ==== */}
      {/* CTA SECTION */}
      {/* ==== */}
      <Flex
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        my="40px"
      >
        {(() => {
          switch (parameter) {
            case "report":
              return (
                <Button
                  bg="yellow"
                  width="300px"
                  fontWeight="900"
                  padding="10px 45px"
                  border="1px solid black"
                  onClick={() => handleRefButtonClick(video0Ref)}
                >
                  BACK TO TOP
                </Button>
              );
            case "start":
              return (
                <Flex gap="20px" alignItems="center" flexDir="column">
                  <Text
                    textAlign="center"
                    fontSize={["16px", "16px", "16px", "18px", "18px"]}
                    mt="10px"
                    fontWeight="500"
                  >
                    You can start now. If you need to stop at any point and{" "}
                    <br /> come back, we’ll save your progress.
                  </Text>
                  <a
                    href="/diagnostic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      bg="yellow"
                      width="260px"
                      fontWeight="900"
                      fontSize={["12px", "12px", "12px", "16px", "16px"]}
                      m="auto"
                      as="a"
                      href="/diagnostic"
                      target="_blank"
                      _hover={{
                        bg: "#b2b200",
                      }}
                    >
                      START MY DIAGNOSTIC
                    </Button>
                  </a>
                </Flex>
              );
            case "progress":
              return (
                <Flex gap="20px" alignItems="center" flexDir="column">
                  <Text
                    textAlign="center"
                    fontSize={["16px", "16px", "16px", "18px", "18px"]}
                    mt="10px"
                    fontWeight="bold"
                  >
                    You’re almost there. We just need you to complete a few
                    <br /> more questions to finish the diagnostic.
                  </Text>
                  <a
                    href="/diagnostic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      bg="yellow"
                      width="260px"
                      fontWeight="900"
                      fontSize={["12px", "12px", "12px", "16px", "16px"]}
                      m="auto"
                      as="a"
                      href="/diagnostic"
                      target="_blank"
                      _hover={{
                        bg: "#b2b200",
                      }}
                    >
                      COMPLETE MY DIAGNOSTIC
                    </Button>
                  </a>
                </Flex>
              );
          }
        })()}
      </Flex>
    </>
  );
};

export default LandingNew;
